import { useState, useEffect } from "react";
import { IdToken, useAuth0 } from "@auth0/auth0-react";

export interface Entitlements {
  pages: boolean;
  pdfs: boolean;
  templates: boolean;
}

interface User {
  userName: string;
  email: string;
  isLoading: boolean;
  isDriverAiUser: boolean | undefined;
  entitlements: Entitlements;
}

const defaultEntitlements: Entitlements = {
  pages: false,
  pdfs: false,
  templates: false,
};

const useUser = (): User => {
  const { isLoading, getIdTokenClaims, user } = useAuth0();
  const [idTokenClaims, setIdTokenClaims] = useState<IdToken | null>(null);
  const driverAiDomains = ["@driverai.com", "@driver.ai"];
  const isDriverAiUser = driverAiDomains.some((domain) =>
    user?.email?.includes(domain)
  );

  useEffect(() => {
    const fetchIdTokenClaims = async () => {
      try {
        const claims = (await getIdTokenClaims()) || null;
        setIdTokenClaims(claims);
      } catch (error) {
        console.error("Error fetching ID token claims:", error);
      }
    };

    fetchIdTokenClaims();
  }, [getIdTokenClaims]);


  // mock entitlements with:
  // localStorage.setItem("entitlements", JSON.stringify({pages: false, pdfs: false, templates: false}))
  const getEntitlements = () => {
    if (typeof window === "undefined") return {};
    const storedEntitlements = localStorage.getItem("entitlements");
    if (!storedEntitlements) return defaultEntitlements;
    const entitlements = JSON.parse(storedEntitlements);
    return entitlements;
  };

  const userName: string = idTokenClaims?.name || "Loading...";
  const email: string = idTokenClaims?.email || "";
  const entitlements = getEntitlements();

  return { userName, email, isLoading, isDriverAiUser, entitlements };
};

export default useUser;