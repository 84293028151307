import { useEffect } from "react";
import { useRouter } from "next/router";
import useUser from "@/hooks/useUser";
import { Entitlements } from "@/hooks/useUser";
import { useOrg } from "@/contexts/OrgContext";

const routeToEntitlementMap: Record<keyof Entitlements, string[]> = {
    "pages": ["/page/"],
    "templates": ["/templates", "/template/"],
    "pdfs": ["/pdf/"]
};

const useEntitlementRedirect = () => {
    const { entitlements, isLoading } = useUser();
    const router = useRouter();
    const { organizationName } = useOrg();

    const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

    useEffect(() => {
        if (isLoading) return
        if (!organizationName) return
        const entitledPaths = Object.values(routeToEntitlementMap).flat();
        const isEntitledPath = entitledPaths.some(path => pathname.includes(path));
        if (!isEntitledPath) return
        const hasAccess = Object.entries(routeToEntitlementMap).some(([key, paths]) => {
            const isEntitledPath = paths.some(path => pathname.includes(path));
            const isEntitled = entitlements[key as keyof Entitlements];
            return isEntitledPath && isEntitled;
        });
        if (!hasAccess) {
            router.push(`/${organizationName}`);
        }
    }, [entitlements, isLoading, router]);
};

export default useEntitlementRedirect; 